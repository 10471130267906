// Import modules socle
import SwiperSlider from 'integration-web-core--socle/js/assets/modules/_swiperSlider.class';
const BRAND_SWIPER_SELECTOR = 'js-brands-swiper';
class BrandsMesh extends lm.Composant {
  constructor(id) {
    super(id);
    const elemSwiperBrands = document.querySelector(`.${BRAND_SWIPER_SELECTOR}`);
    if (elemSwiperBrands) {
      const swiperObject = {};
      swiperObject.instance = new SwiperSlider(`.${BRAND_SWIPER_SELECTOR}`);
      delete swiperObject.instance;
    }
  }
}
lm.DOMReady(function () {
  const sectionElem = document.querySelectorAll('.component-brandsmesh');
  for (let i = 0; i < sectionElem.length; i++) {
    const idElem = sectionElem[i].getAttribute('id');
    if (idElem) {
      const brandMeshObject = {};
      brandMeshObject.instance = new BrandsMesh(idElem);
      delete brandMeshObject.instance;
    }
  }
});